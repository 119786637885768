import React from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import HeaderComponent from '../../components/Header/header.component';

const PlayerScreen = () => {
  // const navigate = useNavigate();

  const columns = [
    {
      name: 'date',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>{value}</strong>;
        },
      },
    },
    {
      name: 'position',
      label: 'Lugar',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'score',
      label: 'Score',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>{value}</strong>;
        },
      },
    },
    {
      name: 'winner',
      label: 'Winner',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          if (value === 'No ganador') {
            return <span className="badges badge-nowin">No ganador</span>;
          } else {
            return <span className="badges badge-win">Ganador</span>;
          }
        },
      },
    },

    {
      name: 'detail',
      label: 'Detalle',
      options: {
        filter: false,
        sort: false,
        customBodyRender: () => {
          return (
            <Link to="/game" className="btn btn--simple">
              Detalle
            </Link>
          );
        },
      },
    },
  ];

  const data = [
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
    {
      date: '28 . Mar . 2022',
      position: '3er Lugar',
      score: '9km',
      winner: 'No ganador',
      detail: 'Detalle',
    },
  ];

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title__back">
              <Link to="/game" className="btn btn--simple-back">
                <span className="material-icons">arrow_back</span>{' '}
                <p>Regresar</p>
              </Link>
            </div>
            <div className="title-board pt-0">
              <h1>Detalle de jugador</h1>
              <div className="detail-data">
                <p className="detail-data__name">
                  <strong>Fecha</strong>
                </p>
                <p className="detail-data__span">
                  <span>Mar 29, 11:31 am</span>
                </p>
              </div>
            </div>
            <div className="detail-info detail--top">
              <div className="row">
                <div className="col-2">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>John Carter</strong>
                    </p>
                    <p className="detail-data__transaction">
                      <span>#3773</span>
                    </p>
                  </div>
                </div>

                <div className="col-3">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Correo electrónico</strong>
                    </p>
                    <p>john.carter@mail.com</p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Teléfono</strong>
                    </p>
                    <p>55 2313 0556</p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Partidas jugadas</strong>
                    </p>
                    <p>32</p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="detail-data">
                    <Link to="/edit-player" className="btn btn--simple">
                      Editar jugador
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="title-board ">
              <h1>Partidas jugadas</h1>
              <Link to="/edit-game" className="btn btn--simple">
                Crear partida
              </Link>
            </div>

            <div className="data-table">
              <MUIDataTable
                title="DataTable"
                data={data}
                columns={columns}
                options={{
                  filterType: 'checkbox',
                  responsive: 'simple',
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PlayerScreen;
