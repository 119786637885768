import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import {
  ITransaction,
  ITransactionPaginate,
} from '../types/TransactionInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getTransactions = async (
  offset: number,
  limit: number,
  searchText: string,
  token: string
) => {
  const response = await api.get<ITransactionPaginate>(
    `/transaction?${encode({
      limit,
      offset,
      searchText,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getTransactionById = async (id: string, token: string) => {
  const response = await api.get<ITransaction>(`/transaction/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const createTransaction = async (
  transaction: ITransaction,
  token: string
) => {
  const response = await api.post<ITransaction>('/transaction', transaction, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateTransaction = async (
  id: string,
  transaction: Partial<ITransaction>,
  token: string
) => {
  const response = await api.patch<ITransaction>(
    `/transaction/${id}`,
    transaction,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const deleteTransaction = async (id: string, token: string) => {
  const response = await api.delete(`/game-played/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchTransaction = async (
  offset: number,
  limit: number,
  sort: string,
  query: Partial<ITransaction>,
  token: string
) => {
  const response = await api.post<ITransactionPaginate>(
    `/transaction/search?${encode({
      limit,
      offset,
      sort,
    } as ParsedUrlQueryInput)}`,
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
