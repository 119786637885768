import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './pages/Home/home.screen';
import LoginScreen from './pages/Auth/login.screen';
import SignupScreen from './pages/Auth/signup.screen';
import RecoverScreen from './pages/Auth/recover.screen';
import SuccessScreen from './pages/Auth/success.screen';
import ErrorScreen from './pages/Auth/error.screen';
import DashboardScreen from './pages/Dashboard/dashboard.screen';
import PlayersScreen from './pages/Players/players.screen';
import GameScreen from './pages/Game/game.screen';
import PlayerScreen from './pages/Players/detail.screen';
import EditGameScreen from './pages/Game/edit.screen';
import EditPlayerScreen from './pages/Players/edit.screen';
import UsersScreen from './pages/Users/user.screen';
import EditUserScreen from './pages/Users/edit.screen';
import ProfileScreen from './pages/Auth/profile.screen';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { getSession } from './utils/helpers';
import TransactionScreen from './pages/Transactions/transaction.screen';

function App() {
  const session = getSession();
  return (
    <main className="app-main">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/recover" element={<RecoverScreen />} />
        <Route path="/success" element={<SuccessScreen />} />
        <Route path="/error" element={<ErrorScreen />} />
        <Route
          path="/dashboard"
          element={session ? <DashboardScreen /> : <LoginScreen />}
        />
        <Route
          path="/players"
          element={session ? <PlayersScreen /> : <LoginScreen />}
        />
        <Route
          path="/game/:id"
          element={session ? <GameScreen /> : <LoginScreen />}
        />
        <Route
          path="/player"
          element={session ? <PlayerScreen /> : <LoginScreen />}
        />
        <Route
          path="/edit-game/:id"
          element={session ? <EditGameScreen /> : <LoginScreen />}
        />
        <Route
          path="/edit-player/new"
          element={session ? <EditPlayerScreen /> : <LoginScreen />}
        />
        <Route
          path="/edit-player/:id"
          element={session ? <EditPlayerScreen /> : <LoginScreen />}
        />
        <Route
          path="/users"
          element={session ? <UsersScreen /> : <LoginScreen />}
        />
        <Route
          path="/edit-user/new"
          element={session ? <EditUserScreen /> : <LoginScreen />}
        />
        <Route
          path="/edit-user/:id"
          element={session ? <EditUserScreen /> : <LoginScreen />}
        />
        <Route
          path="/transactions"
          element={session ? <TransactionScreen /> : <LoginScreen />}
        />
        <Route
          path="/profile"
          element={session ? <ProfileScreen /> : <LoginScreen />}
        />
      </Routes>
    </main>
  );
}

export default App;
