import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { getGamePlayedById } from '../../api/gamesApi';
import HeaderComponent from '../../components/Header/header.component';
import { IGamePlayed } from '../../types/GamesInterface';
import { getSession } from '../../utils/helpers';
import { IPlayer } from '../../types/PlayerInterface';
import { ITransactionPaginate } from '../../types/TransactionInterface';
import { searchTransaction } from '../../api/transactionsApi';

const GameScreen = () => {
  const [game, setGame] = useState<Partial<IGamePlayed>>({});
  const [transactions, setTransactions] = useState<
    Partial<ITransactionPaginate>
  >({});
  const params = useParams();

  useEffect(() => {
    const session = getSession();
    console.log({ params, session });
    if (params.id && session && session.token) {
      getGamePlayedById(params.id || '', session.token)
        .then((game) => {
          setGame(game);
          searchTransaction(
            0,
            2000,
            '',
            { gamePlayedId: game._id },
            session.token
          )
            .then((resp) => {
              if (resp) {
                setTransactions(resp);
              }
            })
            .catch((error) => {
              Swal.fire('Oops', error, 'error');
            });
        })
        .catch((error) => {
          Swal.fire('Oops', error, 'error');
        });
    }
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title__back">
              <Link to="/dashboard" className="btn btn--simple-back">
                <span className="material-icons">arrow_back</span>{' '}
                <p>Regresar</p>
              </Link>
            </div>
            <div className="title-board pt-0">
              <h1>Detalle de partida</h1>
              <div className="detail-data">
                <p className="detail-data__name">
                  <strong>Fecha</strong>
                </p>
                <p className="detail-data__span">
                  <span>
                    {game.createdAt
                      ? `${format(new Date(game.createdAt || ''), 'MMM dd, p')}`
                      : ''}
                  </span>
                </p>
              </div>
            </div>
            <div className="detail-info detail--top">
              <div className="row">
                <div className="col-2">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Partida Id.</strong>
                    </p>
                    <p className="detail-data__id">
                      <span>
                        {game._id
                          ? '#' + `${game._id}`.substr(`${game._id}`.length - 5)
                          : ''}
                      </span>
                    </p>
                    {game.winner ? (
                      <span className="badges badge-win badge--small">
                        Ganador
                      </span>
                    ) : (
                      <span className="badges badge-nowin badge--small">
                        No ganador
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-3">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>
                        {game.playerId ? (game.playerId as IPlayer).name : ''}
                      </strong>
                    </p>
                    <p className="detail-data__id">
                      <span>
                        #
                        {game && game.playerId
                          ? ((game.playerId as IPlayer)._id || '').substr(
                              `${(game.playerId as IPlayer)._id}`.length - 5
                            )
                          : ''}
                      </span>
                    </p>
                    {game && game.playerId ? (
                      <Link
                        to={`/edit-player/${(game.playerId as IPlayer)._id}`}
                        className="btn btn--simple"
                      >
                        Ver detalle
                      </Link>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="col-3">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Correo electrónico</strong>
                    </p>
                    <p>
                      {game.playerId ? (game.playerId as IPlayer).email : ''}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Teléfono</strong>
                    </p>
                    <p>
                      {game.playerId ? (game.playerId as IPlayer).phone : ''}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Score</strong>
                    </p>
                    <p>{game.score || 0}</p>
                    <Link
                      to={`/edit-game/${game._id || ''}`}
                      className="btn btn--simple"
                    >
                      Editar score
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-info">
              {(transactions.docs || []).length ? (
                <div></div>
              ) : (
                <div className="row">
                  <div className="col-2">
                    <div className="detail-data">
                      <p className="detail-data__name">
                        <strong>Sin transacciones vinculadas.</strong>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {(transactions.docs || []).map((transaction, idx) => {
                const { body, receiptNo } = transaction;
                if (body) {
                  const { item } = body;
                  return (
                    <div className="row" key={idx}>
                      <div className="col-2">
                        <div className="detail-data">
                          <p className="detail-data__name">
                            <strong>Transacción.</strong>
                          </p>
                          <p className="detail-data__transaction">
                            <span>{receiptNo || ''}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="detail-data">
                          <p className="detail-data__name">
                            <strong>Referencia.</strong>
                          </p>
                          <p className="detail-data__transaction">
                            <span>{item.merchant_invoice || ''}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="detail-data">
                          <p className="detail-data__name">
                            <strong>Tarjeta</strong>
                          </p>
                          <p className="detail-data__id">
                            <span>
                              <small>**** **** ****</small>
                              {` ${item.card.last4 || ''}`}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="detail-data">
                          <p className="detail-data__name">
                            <strong>Cantidad</strong>
                          </p>
                          <p className="detail-data__transaction">
                            <span>${`${item.amount || '0.00'}`}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="detail-data">
                          <p className="detail-data__name">
                            <strong>Estatus de pago</strong>
                          </p>
                          <span
                            className={`badges ${
                              item.status === 'Paid'
                                ? 'badge-win'
                                : 'badge-nowin'
                            } badge--small`}
                          >
                            {`${
                              item.status === 'Paid' ? 'Pagado' : 'No Pagado'
                            }`}
                          </span>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="detail-data">
                          <p className="detail-data__name">
                            <strong>Fecha</strong>
                          </p>
                          <p className="detail-data__span">
                            <span>{`${format(
                              new Date(item.created_at || ''),
                              'MMM dd, p'
                            )}`}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return <div key={idx}></div>;
              })}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default GameScreen;
