import React from 'react';
import { Link } from 'react-router-dom';

const HomeScreen = () => {
  // const navigate = useNavigate();

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="large-container__background large-container__col col-sm-6 col-12"></div>
        <div className="large-container__init large-container__col col-sm-6 col-12">
          <section className="init-container">
            <figure className="init__logo">
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
            <article className="init__article">
              <h1>Bienvenido</h1>
              <p>Ingresa al administrador</p>

              <div className="init__actions">
                <div className='col-12 col-reset mb-4'>
                  <Link to="/login" className="btn btn--type1">
                    Iniciar
                  </Link>
                </div>
                <div className='col-12 col-reset'>
                  <Link to="/signup" className="btn btn--type2">
                    Registrate
                  </Link>
                </div>
                
              </div>
            </article>
          </section>
        </div>
      </div>
    </section>
  );
};

export default HomeScreen;
