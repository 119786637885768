import React from 'react';
import { Link } from 'react-router-dom';

const SuccessScreen = () => {
  // const navigate = useNavigate();

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <section className="contain-top col-12 col-reset">
            <section className="login-header">
              <figure>
                <img
                  src="/assets/images/logo/logo_holy_car.png"
                  alt="Holy Car - Takes One Shot"
                />
              </figure>
            </section>
            <section className="game-title">
              <h1>La contraseña se recupero correctamente</h1>
            </section>
            <section className="game-info col-sm-6 col-7 mx-auto">
              <figure className="game-info__icon">
                <img src="/assets/images/icon/check.svg" alt="Success" />
              </figure>
            </section>
            <div className="control-box">
              <Link to="/login" className="btn btn--type1">
                Iniciar sesión
              </Link>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default SuccessScreen;
