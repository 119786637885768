import React from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import HeaderComponent from '../../components/Header/header.component';
import { IUser } from '../../types/UserInterface';
import { IUserInvitationPaginate } from '../../types/UserInvitationInterface';
import { getSession } from '../../utils/helpers';
import {
  getUserInvitations,
  getUsers,
  newUserInvitations,
} from '../../api/usersApi';

type UsersPageProps = {
  [key: string]: any;
};

type UsersPageState = {
  session: Partial<IUser>;
  users: IUser[];
  invitationsPaginate?: IUserInvitationPaginate;
  offset: number;
  limit: number;
  count: number;
  page: number;
  totalPages: number;
  activePage: number;
  columns: MUIDataTableColumnDef[];
  invitationsColumns: MUIDataTableColumnDef[];
};

class UsersScreen extends React.Component<UsersPageProps, UsersPageState> {
  constructor(props: UsersPageProps) {
    super(props);
    this.state = {
      session: {},
      users: [],
      offset: 0,
      limit: 100,
      count: 0,
      page: 0,
      totalPages: 0,
      activePage: 1,
      columns: [
        {
          name: '_id',
          label: 'ID',
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value: string) => {
              return <strong>#{value}</strong>;
            },
          },
        },
        {
          name: 'username',
          label: 'Nombre',
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: 'email',
          label: 'Correo electrónico',
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: 'createdAt',
          label: 'Fecha de creación',
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value: string) => {
              return <strong>{value}</strong>;
            },
          },
        },
        {
          name: 'rol',
          label: 'Tipo de usuario',
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value: string) => {
              if (value === 'USER') {
                return <span className="badges badge-nowin">Usuario</span>;
              } else {
                return <span className="badges badge-win">Admin</span>;
              }
            },
          },
        },
        {
          name: 'id',
          label: 'Detalle',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string) => {
              const adminStyle =
                this.state.session.rol === 'ADMIN' ? '' : 'hidden';
              return (
                <Link
                  to={`/edit-user/${value}`}
                  className={`btn btn--simple ${adminStyle}`}
                >
                  Detalle
                </Link>
              );
            },
          },
        },
      ],
      invitationsColumns: [
        {
          name: '_id',
          label: 'ID',
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value: string) => {
              return <strong>#{value}</strong>;
            },
          },
        },
        {
          name: 'invCode',
          label: 'Código de invitación',
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: 'expires',
          label: 'Expira',
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: 'available',
          label: 'Usuario',
          options: {
            filter: true,
            sort: true,
          },
        },
      ],
    };

    this.getUsersList = this.getUsersList.bind(this);
    this.getUserInvitations = this.getUserInvitations.bind(this);
    this.createUserInvitation = this.createUserInvitation.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const session = getSession();
    if (session) {
      this.setState({ session });
    }
    if (session?.token) {
      this.getUsersList(0, 10, session.token, '');
      setTimeout(() => {
        this.getUserInvitations(0, 5, session.token);
      }, 500);
    }
  }

  getUsersList(
    offset: number,
    limit: number,
    token: string,
    searchText: string
  ) {
    getUsers(offset, limit, searchText, token)
      .then((usersPaginate) => {
        const {
          docs: users,
          totalDocs: count,
          page,
          totalPages,
        } = usersPaginate;
        this.setState({ users, count, page: page - 1, totalPages });
      })
      .catch((error) => {
        Swal.fire('Oops', error, 'error');
      });
  }

  getUserInvitations(offset: number, limit: number, token: string) {
    const {
      state: {
        session: { rol },
      },
    } = this;
    if (rol === 'ADMIN') {
      getUserInvitations(offset, limit, token)
        .then((resp) => {
          this.setState({ invitationsPaginate: resp });
        })
        .catch((error) => {
          Swal.fire('Oops!', `${error}`, 'warning');
        });
    }
  }

  createUserInvitation() {
    const {
      state: {
        session: { token },
      },
    } = this;
    if (token) {
      newUserInvitations(token)
        .then((resp) => {
          Swal.fire(
            'Listo',
            `Se ha generado la invitación ID ${resp.invCode}`,
            'success'
          );
          this.getUserInvitations(0, 5, token);
        })
        .catch((error) => {
          Swal.fire('Oops', error, 'error');
        });
    }
  }

  handleSelect(eventKey: number) {
    const {
      state: {
        limit,
        session: { token },
      },
    } = this;
    this.getUsersList(eventKey * limit - limit, limit, `${token}`, '');
    this.setState({
      activePage: eventKey,
    });
  }

  render() {
    const isUserAdmin = this.state.session.rol === 'ADMIN';
    const styleForNonAdmin = isUserAdmin ? '' : 'hidden';
    return (
      <section className="large-container container-fluid">
        <div className="row">
          <div className="col-12">
            <HeaderComponent />
            <section className="contain-top-2 col-12 col-reset">
              <div className="title-board">
                <h1>Usuarios</h1>
                {isUserAdmin ? (
                  <Link to="/edit-user/new" className="btn btn--simple">
                    Crear usuario
                  </Link>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="data-table">
                <MUIDataTable
                  title="Tabla Usuarios"
                  data={this.state.users.map((user) => {
                    return {
                      ...user,
                      _id: `${user._id}`.substr(`${user._id}`.length - 5),
                      id: user._id || '',
                      createdAt: format(
                        new Date(user.createdAt || ''),
                        'dd/MM/yyy - HH:mm'
                      ),
                    };
                  })}
                  columns={this.state.columns}
                  options={{
                    filterType: 'checkbox',
                    responsive: 'simple',
                  }}
                />
              </div>
            </section>
            <section className="contain-top-2 col-12 col-reset">
              <div className="title-board">
                <h1>Invitaciones</h1>
                <button
                  className={`btn btn--simple ${styleForNonAdmin}`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.createUserInvitation();
                  }}
                >
                  Crear invitación
                </button>
              </div>
              <div className="data-table">
                <MUIDataTable
                  title="Tabla Invitaciones"
                  data={(this.state.invitationsPaginate?.docs || []).map(
                    (invitation) => {
                      return {
                        ...invitation,
                        _id: `${invitation._id}`.substr(
                          `${invitation._id}`.length - 5
                        ),
                        id: invitation._id || '',
                        expires: format(
                          new Date(invitation.expires || ''),
                          'dd/MM/yyyy'
                        ),
                        available: invitation.userId ? 'Ocupado' : 'Disponible',
                      };
                    }
                  )}
                  columns={this.state.invitationsColumns}
                  options={{
                    filterType: 'checkbox',
                    responsive: 'simple',
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default UsersScreen;
