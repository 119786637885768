import React from 'react';
import { Link } from 'react-router-dom';

const ErrorScreen = () => {
  // const navigate = useNavigate();

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <section className="contain-top col-12 col-reset">
            <section className="login-header">
              <figure>
                <img
                  src="/assets/images/logo/logo_holy_car.png"
                  alt="Holy Car - Takes One Shot"
                />
              </figure>
            </section>
            <section className="game-title">
              <h1>Oops! ocurrió un error</h1>
            </section>
            <section className="game-info col-sm-6 col-7 mx-auto">
              <figure className="game-info__icon">
                <img src="assets/images/icon/error.svg" alt="Success" />
              </figure>
              <article>
                <p>Intentálo de nuevo</p>
              </article>
            </section>
            <div className="control-box">
              <Link to="/recover" className="btn btn--type1">
                Regresar
              </Link>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ErrorScreen;
