import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { getGamesPlayed } from '../../api/gamesApi';
import { IIGamePlayedPaginate } from '../../types/GamesInterface';
import { IPlayer } from '../../types/PlayerInterface';
import { getSession } from '../../utils/helpers';
import { updateTransaction } from '../../api/transactionsApi';

interface Props {
  show: boolean;
  onHide: () => void;
  transactionId: string;
  sessionToken: string;
}

const AssignGameModal = ({
  show,
  onHide,
  transactionId,
  sessionToken,
}: Props) => {
  const [games, setGames] = useState<IIGamePlayedPaginate>();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>#{value}</strong>;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>{value}</strong>;
        },
      },
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Correo electrónico',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'score',
      label: 'Score',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="data-table__span">{value}</span>;
        },
      },
    },
    {
      name: 'data',
      label: 'Seleccionar',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data: {
          gamePlayedId: string;
          playerId: string;
        }) => {
          const { gamePlayedId, playerId } = data;
          return (
            <button
              onClick={(e) => {
                e.preventDefault();
                return Swal.fire({
                  title: '¿Estás segur@?',
                  text: '¿Deseas asignar esta partida a la transacción?',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Si, asignar',
                }).then((result) => {
                  if (result.isConfirmed) {
                    return updateTransaction(
                      transactionId,
                      { gamePlayedId, playerId },
                      sessionToken
                    )
                      .then(() => {
                        setTimeout(() => {
                          window.location.replace(`/game/${gamePlayedId}`);
                        }, 1000);
                        return Swal.fire(
                          'Finalizado',
                          'El juego ha sido guardado. Gracias',
                          'success'
                        );
                      })
                      .catch((error) => {
                        Swal.fire('Oops', error, 'error');
                      });
                  }
                });
              }}
              className="btn btn--simple"
            >
              Seleccionar
            </button>
          );
        },
      },
    },
  ];

  const getGamesPlayedDocs = async () => {
    try {
      const session = getSession();
      if (session && session.token) {
        const result = await getGamesPlayed(0, 1000, '', session.token);
        if (result) {
          setGames(result);
        }
      }
    } catch (error) {
      console.log('getGamesPlayedDocs', error);
    }
  };

  useEffect(() => {
    getGamesPlayedDocs();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="lg"
      animation={false}
    >
      <Modal.Header closeButton>
        <h5 className="modal-title" id="modalComponentLabel">
          Asignar partida
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="data-table">
          <MUIDataTable
            title={'Asignar partida'}
            data={(games?.docs || []).map((game) => {
              return {
                ...game,
                _id: `${game._id}`.substr(`${game._id}`.length - 5),
                id: game._id || '',
                createdAt: format(
                  new Date(game.createdAt || ''),
                  'dd . MMM . yyy'
                ),
                name: (game.playerId as IPlayer).name,
                email: (game.playerId as IPlayer).email,
                phone: (game.playerId as IPlayer).phone,
                data: {
                  gamePlayedId: game._id || '',
                  playerId: (game.playerId as IPlayer)._id,
                  transactionId,
                },
              };
            })}
            columns={columns}
            options={{
              download: false,
              print: false,
              selectableRows: 'none',
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn--type2 btn--small"
          onClick={(evt) => {
            evt.preventDefault();
            onHide();
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignGameModal;
