import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IUser } from '../../types/UserInterface';
import { signUp } from '../../api/usersApi';
import { saveSession } from '../../utils/helpers';

const SignupScreen = () => {
  const [user, setUserData] = useState<Partial<IUser>>({
    username: '',
    email: '',
    password: '',
    invCode: 0,
  });
  // const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const signUpUser = () => {
    const valid = Object.keys(user).every((key) => (user as any)[key] !== '');
    console.log('valid', valid);
    if (!valid) {
      return Swal.fire(
        'Oops!',
        'Faltan campos por llenar. La contraseña debe ser mayor a 8 caracteres y el código de validación igual a 5',
        'warning'
      );
    }
    return signUp(user)
      .then((signUser) => {
        saveSession(signUser);
        window.location.replace('/dashboard');
      })
      .catch((error) => {
        Swal.fire('Oops!', `${error}`, 'warning');
      });
  };

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <header
            className={
              scroll ? 'header-nav col-12 active' : 'header-nav col-12'
            }
          >
            <Link to="/" className="btn btn--simple-back">
              <span className="material-icons">arrow_back</span> <p>Regresar</p>
            </Link>
          </header>
          <section className="contain-top col-12 col-reset">
            <section className="login-header">
              <figure>
                <img
                  src="/assets/images/logo/logo_holy_car.png"
                  alt="Holy Car - Takes One Shot"
                />
              </figure>
            </section>
            <section className="game-title">
              <h1>Registro</h1>
            </section>
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                signUpUser();
              }}
            >
              <section className="game-form col-sm-5 col-6 mx-auto">
                <div className="game-form__row">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Ingresa tu nombre completo"
                    onChangeCapture={(evt) =>
                      setUserData({
                        ...user,
                        username:
                          (evt.target as HTMLTextAreaElement).value || '',
                      })
                    }
                  />
                </div>
                <div className="game-form__row">
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="micorreo@mail.com"
                    inputMode="email"
                    onChangeCapture={(evt) =>
                      setUserData({
                        ...user,
                        email: (evt.target as HTMLTextAreaElement).value || '',
                      })
                    }
                  />
                </div>
                <div className="game-form__row">
                  <label htmlFor="password" className="form-label">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="********"
                    onChangeCapture={(evt) => {
                      if (
                        (evt.target as HTMLTextAreaElement).value.length >= 8
                      ) {
                        return setUserData({
                          ...user,
                          password:
                            (evt.target as HTMLTextAreaElement).value || '',
                        });
                      }
                    }}
                  />
                </div>
                <div className="game-form__row">
                  <label htmlFor="code" className="form-label">
                    Código de validación
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    placeholder="AXJDHDJ"
                    onChangeCapture={(evt) => {
                      if (
                        (evt.target as HTMLTextAreaElement).value.length === 5
                      ) {
                        return setUserData({
                          ...user,
                          invCode: Number(
                            (evt.target as HTMLTextAreaElement).value
                          ),
                        });
                      }
                    }}
                  />
                </div>
              </section>
              <div className="control-box control--100">
                <div className="control-box__col col-12 col-reset">
                  <button type="submit" className="btn btn--type1">
                    Crear cuenta
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </section>
  );
};

export default SignupScreen;
