import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { forgotPass } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import RecoverPassModal from './modal-recover.component';

const RecoverScreen = () => {
  const [user, setUser] = useState<Partial<IUser>>({});
  const [email, setEmail] = useState('');
  const [resetPass, setResetPass] = useState('');
  const [visibleRecover, setVisibleRecover] = useState(false);
  // const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <section className="large-container container-fluid">
      <RecoverPassModal
        user={user}
        resetPass={Number(resetPass)}
        isModalVisible={visibleRecover}
        toggleModal={() => setVisibleRecover(!visibleRecover)}
      />
      <div className="row">
        <div className="col-12">
          <header
            className={
              scroll ? 'header-nav col-12 active' : 'header-nav col-12'
            }
          >
            <Link to="/login" className="btn btn--simple-back">
              <span className="material-icons">arrow_back</span> <p>Regresar</p>
            </Link>
          </header>
          <section className="contain-top col-12 col-reset">
            <section className="login-header">
              <figure>
                <img
                  src="/assets/images/logo/logo_holy_car.png"
                  alt="Holy Car - Takes One Shot"
                />
              </figure>
            </section>
            <section className="game-title">
              <h1>Recuperar contraseña</h1>
              <p>Ingresa el correo electrónico con el que inciaste tu cuenta</p>
            </section>
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                forgotPass(email)
                  .then((resp) => {
                    setUser(resp.data);
                    Swal.fire({
                      title: 'Listo',
                      text: 'Revisa tu correo electrónico e introduce el código que te hemos enviado',
                      input: 'number',
                      showCancelButton: true,
                      confirmButtonText: 'Confirmar',
                      showLoaderOnConfirm: true,
                      preConfirm: (validationCode: number) => {
                        if (Number(validationCode) === resp.data.resetPass) {
                          return {
                            valid: true,
                            resetPass: `${resp.data.resetPass}`,
                          };
                        }
                        return { valid: false, resetPass: '' };
                      },
                      allowOutsideClick: false,
                    }).then((result) => {
                      console.log(result);
                      if (result.value?.valid) {
                        setResetPass(result.value?.resetPass);
                        setTimeout(() => {
                          setVisibleRecover(true);
                        }, 500);
                      } else {
                        Swal.fire('Oops!', 'El código es inválido', 'error');
                      }
                    });
                  })
                  .catch((error) => Swal.fire('Oops!', error, 'error'));
              }}
            >
              <section className="game-form col-sm-5 col-6 mx-auto">
                <div className="game-form__row">
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="micorreo@mail.com"
                    inputMode="email"
                    onChangeCapture={(evt) =>
                      setEmail((evt.target as HTMLTextAreaElement).value || '')
                    }
                  />
                </div>
              </section>
              <div className="control-box control--100">
                <div className="control-box__col col-12 col-reset">
                  <button type="submit" className="btn btn--type1">
                    Recuperar
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </section>
  );
};

export default RecoverScreen;
