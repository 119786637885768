import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  getUser,
  newUserInvitations,
  signUp,
  updateUser,
} from '../../api/usersApi';
import HeaderComponent from '../../components/Header/header.component';
import { IUser } from '../../types/UserInterface';
import { getSession } from '../../utils/helpers';

const EditUserScreen = () => {
  const [user, setUser] = useState<Partial<IUser>>({});
  const [currentSession, setSession] = useState<IUser | null>();
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  console.log('this.props.match?.params?', user);

  useEffect(() => {
    const session = getSession();
    setSession(session);
    if (params.id && session && session.token) {
      getUser(params.id || '', session.token)
        .then((user) => {
          setUser(user);
        })
        .catch((error) => {
          Swal.fire('Oops', error, 'error');
        });
    } else {
      setIsNew(true);
    }
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title__back">
              <Link to="/users" className="btn btn--simple-back">
                <span className="material-icons">arrow_back</span>{' '}
                <p>Regresar</p>
              </Link>
            </div>
            <div className="title-board pt-0">
              <h1>{`${!isNew ? 'Editar' : 'Crear'}`} usuario</h1>
            </div>
            <div className="detail-info col-6 mx-auto">
              <form>
                <div className="row">
                  <div className="game-form__row">
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Ingresa tu nombre completo"
                      value={user?.username || ''}
                      onChangeCapture={(evt) =>
                        setUser({
                          ...user,
                          username:
                            (evt.target as HTMLTextAreaElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="game-form__row">
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="micorreo@mail.com"
                      inputMode="email"
                      value={user?.email || ''}
                      onChangeCapture={(evt) =>
                        setUser({
                          ...user,
                          email:
                            (evt.target as HTMLTextAreaElement).value || '',
                        })
                      }
                    />
                  </div>
                  {isNew ? (
                    <div className="game-form__row">
                      <label htmlFor="password" className="form-label">
                        Contraseña
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="********"
                        onChangeCapture={(evt) => {
                          if (
                            (evt.target as HTMLTextAreaElement).value.length >=
                            8
                          ) {
                            return setUser({
                              ...user,
                              password:
                                (evt.target as HTMLTextAreaElement).value || '',
                            });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="game-form__row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={user?.rol === 'ADMIN'}
                        id="superadmin"
                        onChange={(event) => {
                          const rol = event.target.checked ? 'ADMIN' : 'USER';
                          setUser({
                            ...user,
                            rol,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="superadmin">
                        Hacer super admin
                      </label>
                    </div>
                  </div>
                  <div className="control-box control--100">
                    <div className="control-box__col col-12 col-reset">
                      <button
                        className="btn btn--type1"
                        onClick={async (e) => {
                          e.preventDefault();
                          let invCode = 0;
                          const token = currentSession?.token;
                          console.log({ isNew });
                          if (isNew && token) {
                            const invitation = await newUserInvitations(token);
                            if (invitation) {
                              invCode = invitation.invCode || 0;
                            }
                          }
                          return Swal.fire({
                            title: '¿Estás segur@?',
                            text: isNew
                              ? '¿Deseas guardar el nuevo usuario?'
                              : '¿Deseas guardar los cambios?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Si, guardar',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              return (
                                isNew
                                  ? signUp({ ...user, invCode })
                                  : updateUser(
                                      user._id || '',
                                      { ...user },
                                      currentSession?.token || ''
                                    )
                              )
                                .then(() => {
                                  setTimeout(() => {
                                    window.location.replace('/users');
                                  }, 1000);
                                  return Swal.fire(
                                    'Finalizado',
                                    'El usuario ha sido guardado. Gracias',
                                    'success'
                                  );
                                })
                                .catch((error) => {
                                  Swal.fire('Oops', error, 'error');
                                });
                            }
                          });
                        }}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default EditUserScreen;
