import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { recoverPass } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import { saveSession } from '../../utils/helpers';

type Props = {
  user: Partial<IUser>;
  resetPass: number;
  isModalVisible: boolean;
  toggleModal: () => void;
};

const RecoverPassModal = ({
  user,
  resetPass,
  isModalVisible,
  toggleModal,
}: Props) => {
  const [passwords, setRecoverPass] = useState({ pass: '', confirm: '' });
  return (
    <Modal
      show={isModalVisible}
      onHide={toggleModal}
      backdrop="static"
      keyboard={true}
      size="sm"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Establecer contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="form-row">
                <label htmlFor="login" className="form-label">
                  Introduce tu nueva contraseña
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="login"
                  aria-describedby="emailHelp"
                  placeholder="*********"
                  onChangeCapture={(evt) =>
                    setRecoverPass({
                      ...passwords,
                      pass: (evt.target as HTMLTextAreaElement).value || '',
                    })
                  }
                />
              </div>
              <div className="form-row">
                <label htmlFor="login" className="form-label">
                  Confirmar contraseña
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="login"
                  aria-describedby="emailHelp"
                  placeholder="*********"
                  onChangeCapture={(evt) =>
                    setRecoverPass({
                      ...passwords,
                      confirm: (evt.target as HTMLTextAreaElement).value || '',
                    })
                  }
                />
              </div>
              <div className="form-row form--bottom text-center">
                <button
                  type="submit"
                  className="btn btn--type1"
                  onClick={(evt) => {
                    evt.preventDefault();
                    const { pass, confirm } = passwords;
                    if (pass === confirm) {
                      toggleModal();
                      return recoverPass(
                        { resetPass, password: passwords.pass },
                        `${user._id}`
                      )
                        .then((resp) => {
                          const signUser = resp.data;
                          saveSession(signUser);
                          window.location.replace('/dashboard');
                        })
                        .catch((error) => Swal.fire('Oops!', error, 'error'));
                    }
                    return Swal.fire(
                      'Oops!',
                      'Las contraseñas no coinciden',
                      'warning'
                    );
                  }}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RecoverPassModal;
