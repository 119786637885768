import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import AssignGameModal from './assign-game.modal';
import { getTransactions } from '../../api/transactionsApi';
import { getSession } from '../../utils/helpers';
import { ITransactionPaginate } from '../../types/TransactionInterface';
import HeaderComponent from '../../components/Header/header.component';
import { IPlayer } from '../../types/PlayerInterface';
import { IGamePlayed } from '../../types/GamesInterface';
import { IUser } from '../../types/UserInterface';

const TransactionScreen = () => {
  const [currentSession, setSession] = useState<IUser | null>();
  const [transactions, setTransactions] = useState<
    Partial<ITransactionPaginate>
  >({});
  const [showModal, setShowModal] = useState(false);
  const [selectedTransactionId, setTransactionId] = useState('');

  useEffect(() => {
    const session = getSession();
    setSession(session);
    if (session && session.token) {
      getTransactions(0, 2000, '', session?.token)
        .then((resp) => {
          setTransactions(resp);
        })
        .catch((error) => {
          Swal.fire('Oops', error, 'error');
        });
    }
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <AssignGameModal
            show={showModal}
            onHide={() => setShowModal(false)}
            transactionId={selectedTransactionId}
            sessionToken={currentSession?.token || ''}
          />
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title-board">
              <h1>Transacciones</h1>
            </div>
            <div className="data-table">
              <MUIDataTable
                title={'Transacciones'}
                data={(transactions?.docs || [])
                  .sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return (
                      new Date(b.body.item.created_at).getTime() -
                      new Date(a.body.item.created_at).getTime()
                    );
                  })
                  .map((transaction, idx) => {
                    const resp = {
                      ...transaction,
                      _id: `${transaction._id}`.substr(
                        `${transaction._id}`.length - 5
                      ),
                      id: transaction._id || '',
                      createdAt: format(
                        new Date(transaction.body.item.created_at || ''),
                        'dd . MMM . yyy'
                      ),
                      name: transaction.playerId
                        ? (transaction.playerId as IPlayer).name
                        : 'Sin asignar',
                      position: idx + 1,
                      data: {
                        transactionId: transaction._id || '',
                        playerId: transaction.playerId
                          ? (transaction.playerId as IPlayer)._id
                          : '',
                        gamePlayedId: transaction.gamePlayedId
                          ? (transaction.gamePlayedId as IGamePlayed)._id
                          : '',
                      },
                    };
                    console.log({ resp });
                    return resp;
                  })}
                columns={[
                  {
                    name: '_id',
                    label: 'ID',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value: string) => {
                        return <strong>#{value}</strong>;
                      },
                    },
                  },
                  {
                    name: 'createdAt',
                    label: 'Fecha',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value: string) => {
                        return <strong>{value}</strong>;
                      },
                    },
                  },
                  {
                    name: 'body',
                    label: 'Transacción',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value) => {
                        return <strong>{value.item.receipt_no}</strong>;
                      },
                    },
                  },
                  {
                    name: 'body',
                    label: 'Referencia',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value) => {
                        return <strong>{value.item.merchant_invoice}</strong>;
                      },
                    },
                  },
                  {
                    name: 'body',
                    label: 'Monto',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value) => {
                        return <strong>${value.item.amount}</strong>;
                      },
                    },
                  },
                  {
                    name: 'body',
                    label: 'Tarjeta',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value) => {
                        return (
                          <div>
                            <span>{`${value.item.card.brand}`}</span>
                            <br />
                            <span>
                              {`**** **** **** ${value.item.card.last4}`}
                            </span>
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'body',
                    label: 'Estatus',
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: ({ item }) => {
                        return (
                          <span
                            className={`badges ${
                              item.status === 'Paid'
                                ? 'badge-win'
                                : 'badge-nowin'
                            } badge--small`}
                          >
                            {`${
                              item.status === 'Paid' ? 'Pagado' : 'No Pagado'
                            }`}
                          </span>
                        );
                      },
                    },
                  },
                  {
                    name: 'data',
                    label: 'Vincular',
                    options: {
                      filter: false,
                      sort: false,
                      customBodyRender: ({
                        transactionId,
                        playerId,
                        gamePlayedId,
                      }) => {
                        console.log({ transactionId, playerId, gamePlayedId });
                        return playerId ? (
                          <Link
                            to={`/game/${gamePlayedId}`}
                            className="btn btn--simple"
                          >
                            Detalle
                          </Link>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setTransactionId(transactionId);
                              setShowModal(true);
                            }}
                            className="btn btn--simple"
                          >
                            Vincular
                          </button>
                        );
                      },
                    },
                  },
                ]}
                options={{
                  filterType: 'checkbox',
                  responsive: 'simple',
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default TransactionScreen;
