import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import HeaderComponent from '../../components/Header/header.component';
import { IIGamePlayedPaginate } from '../../types/GamesInterface';
import { getSession } from '../../utils/helpers';
import { getGamesPlayed } from '../../api/gamesApi';
import { IPlayer } from '../../types/PlayerInterface';

const DashboardScreen = () => {
  const [games, setGames] = useState<IIGamePlayedPaginate>();
  const [rol, setRol] = useState('USER');
  console.log(rol);
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: string) => {
          return <strong>#{value}</strong>;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>{value}</strong>;
        },
      },
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Correo electrónico',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'position',
      label: 'Lugar',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <span className="data-table__span">{value}</span>;
        },
      },
    },
    {
      name: 'score',
      label: 'Score',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <span className="data-table__span">{value}</span>;
        },
      },
    },
    {
      name: 'isCash',
      label: 'Pago',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return (
            <span className="data-table__span">{`${
              value ? 'Efectivo' : 'Tarjeta'
            }`}</span>
          );
        },
      },
    },
    {
      name: 'winner',
      label: 'Winner',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: boolean) => {
          if (!value) {
            return <span className="badges badge-nowin">No ganador</span>;
          } else {
            return <span className="badges badge-win">Ganador</span>;
          }
        },
      },
    },
    {
      name: 'id',
      label: 'Detalle',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return (
            <Link to={`/game/${value}`} className="btn btn--simple">
              Detalle
            </Link>
          );
        },
      },
    },
  ];

  const getGamesPlayedDocs = async () => {
    try {
      const session = getSession();
      if (session && session.token) {
        setRol(session.rol || 'USER');
        const result = await getGamesPlayed(0, 2000, '', session.token);
        if (result) {
          setGames(result);
        }
      }
    } catch (error) {
      console.log('getGamesPlayedDocs', error);
    }
  };

  useEffect(() => {
    getGamesPlayedDocs();
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title-board">
              <h1>Partidas jugadas</h1>
            </div>
            <div className="data-table">
              <MUIDataTable
                title={'Data Table'}
                data={(games?.docs || [])
                  // .sort((a, b) => (a.score > b.score ? -1 : 1))
                  .map((game) => {
                    const gamesCopy = [...(games?.docs || [])];
                    const sortedByScore = gamesCopy.sort((a, b) =>
                      a.score > b.score ? -1 : 1
                    );
                    const position = sortedByScore?.findIndex(
                      (a) => a._id === game._id
                    );
                    return {
                      ...game,
                      _id: `${game._id}`.substr(`${game._id}`.length - 5),
                      id: game._id || '',
                      createdAt: format(
                        new Date(game.createdAt || ''),
                        'dd . MMM . yyy'
                      ),
                      name: (game.playerId as IPlayer).name,
                      email: (game.playerId as IPlayer).email,
                      phone: (game.playerId as IPlayer).phone,
                      position: position || 1,
                    };
                  })}
                columns={columns}
                options={{
                  filterType: 'checkbox',
                  responsive: 'simple',
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default DashboardScreen;
