import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderComponent from '../../components/Header/header.component';
import { getSession } from '../../utils/helpers';
import { IPlayer } from '../../types/PlayerInterface';
import { IUser } from '../../types/UserInterface';
import {
  createPlayer,
  getPlayerById,
  updatePlayer,
} from '../../api/playersApi';

const EditPlayerScreen = () => {
  const [player, setPlayer] = useState<Partial<IPlayer>>({});
  const [currentSession, setSession] = useState<IUser | null>();
  const [isNew, setIsNew] = useState(false);
  const params = useParams();

  useEffect(() => {
    const session = getSession();
    setSession(session);
    if (params.id && session && session.token) {
      getPlayerById(params.id || '', session.token)
        .then((player) => {
          setPlayer(player);
        })
        .catch((error) => {
          Swal.fire('Oops', error, 'error');
        });
    } else {
      setIsNew(true);
    }
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title__back">
              <Link to="/players" className="btn btn--simple-back">
                <span className="material-icons">arrow_back</span>{' '}
                <p>Regresar</p>
              </Link>
            </div>
            <div className="title-board pt-0">
              <h1>Editar jugador</h1>
            </div>
            <div className="detail-info col-6 mx-auto">
              <form>
                <div className="row">
                  <div className="game-form__row">
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Ingresa tu nombre completo"
                      value={player?.name || ''}
                      onChangeCapture={(evt) =>
                        setPlayer({
                          ...player,
                          name: (evt.target as HTMLTextAreaElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="game-form__row">
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="micorreo@mail.com"
                      inputMode="email"
                      value={player?.email || ''}
                      onChangeCapture={(evt) =>
                        setPlayer({
                          ...player,
                          email:
                            (evt.target as HTMLTextAreaElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="game-form__row">
                    <label htmlFor="phone" className="form-label">
                      Teléfono
                    </label>
                    <input
                      type="test"
                      className="form-control"
                      id="phone"
                      placeholder="55 1234 5678"
                      value={player?.phone || ''}
                      onChangeCapture={(evt) =>
                        setPlayer({
                          ...player,
                          phone:
                            (evt.target as HTMLTextAreaElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="control-box control--100">
                    <div className="control-box__col col-12 col-reset">
                      <button
                        className="btn btn--type1"
                        onClick={async (e) => {
                          e.preventDefault();
                          const token = currentSession?.token;
                          return Swal.fire({
                            title: '¿Estás segur@?',
                            text: isNew
                              ? '¿Deseas guardar el nuevo jugador?'
                              : '¿Deseas guardar los cambios?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Si, guardar',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              return (
                                isNew
                                  ? createPlayer(player as IPlayer, `${token}`)
                                  : updatePlayer(
                                      player._id || '',
                                      { ...player },
                                      currentSession?.token || ''
                                    )
                              )
                                .then(() => {
                                  setTimeout(() => {
                                    window.location.replace('/players');
                                  }, 1000);
                                  return Swal.fire(
                                    'Finalizado',
                                    'El jugador ha sido guardado. Gracias',
                                    'success'
                                  );
                                })
                                .catch((error) => {
                                  Swal.fire('Oops', error, 'error');
                                });
                            }
                          });
                        }}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default EditPlayerScreen;
