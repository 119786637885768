import React from 'react';
import { Link } from 'react-router-dom';
import HeaderComponent from '../../components/Header/header.component';
import { getSession } from '../../utils/helpers';

const ProfileScreen = () => {
  const session = getSession();
  // const navigate = useNavigate();
  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title__back">
              <Link to="/users" className="btn btn--simple-back">
                <span className="material-icons">arrow_back</span>{' '}
                <p>Regresar</p>
              </Link>
            </div>
            <div className="title-board pt-0">
              <h1>Editar usuario</h1>
            </div>
            <div className="detail-info col-6 mx-auto">
              <form>
                <div className="row">
                  <div className="game-form__row">
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Ingresa tu nombre completo"
                      value={session?.username || ''}
                      disabled={true}
                    />
                  </div>
                  <div className="game-form__row">
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="micorreo@mail.com"
                      inputMode="email"
                      value={session?.email || ''}
                      disabled={true}
                    />
                  </div>
                  <div className="control-box control--100">
                    <div className="control-box__col col-12 col-reset">
                      <Link to="/dashboard" className="btn btn--type1">
                        Guardar
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ProfileScreen;
