import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import HeaderComponent from '../../components/Header/header.component';
import { getPlayers } from '../../api/playersApi';
import { getSession } from '../../utils/helpers';
import { IPlayerPaginate } from '../../types/PlayerInterface';

const PlayersScreen = () => {
  const [players, setPlayers] = useState<IPlayerPaginate>();
  const [rol, setRol] = useState('USER');
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>#{value}</strong>;
        },
      },
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Correo electrónico',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'phone',
      label: 'Teléfono',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return <strong>{value}</strong>;
        },
      },
    },
    {
      name: 'id',
      label: 'Detalle',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return (
            <Link to={`/edit-player/${value}`} className="btn btn--simple">
              Detalle
            </Link>
          );
        },
      },
    },
  ];

  const getPlayersDocs = async () => {
    try {
      const session = getSession();
      if (session && session.token) {
        setRol(session.rol || 'USER');
        const result = await getPlayers(0, 2000, '', session.token);
        if (result) {
          setPlayers(result);
        }
      }
    } catch (error) {
      console.log('getPlayers', error);
    }
  };

  useEffect(() => {
    getPlayersDocs();
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title-board">
              <h1>Jugadores</h1>
              {rol === 'ADMIN' ? (
                <Link to="/edit-player/new" className="btn btn--simple">
                  Crear jugador
                </Link>
              ) : (
                <div></div>
              )}
            </div>
            <div className="data-table">
              <MUIDataTable
                title="Tabla Jugadores"
                data={(players?.docs || []).map((player) => {
                  return {
                    ...player,
                    _id: `${player._id}`.substr(`${player._id}`.length - 5),
                    id: player._id || '',
                    createdAt: format(
                      new Date(player.createdAt || ''),
                      'dd . MMM . yyy'
                    ),
                  };
                })}
                columns={columns}
                options={{
                  filterType: 'checkbox',
                  responsive: 'simple',
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PlayersScreen;
