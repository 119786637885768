import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { deleteSession, getSession } from '../../utils/helpers';

const HeaderComponent = () => {
  const session = getSession();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <header className={scroll ? 'header col-12 active' : 'header col-12'}>
      <Link to="/dashboard" className="header-logo">
        <figure>
          <img
            src="/assets/images/logo/logo_holy_car.png"
            alt="Holy Car - Takes One Shot"
          />
        </figure>
      </Link>
      <nav className="main-nav">
        <ul>
          <li>
            <NavLink to="/dashboard"
              style={
                  ({isActive}) => (
                  isActive 
                  ? {
                      color: '#2052F1'
                    }
                  :{}
                  )
                }>
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink to="/players"
             style={
              ({isActive}) => (
              isActive 
              ? {
                  color: '#2052F1'
                }
              :{}
              )
            }
            >Jugadores</NavLink>
          </li>
          <li>
            <NavLink to="/transactions"
             style={
              ({isActive}) => (
              isActive 
              ? {
                  color: '#2052F1'
                }
              :{}
              )
            }
            >Transacciones</NavLink>
          </li>
          <li>
            <NavLink to="/users"
               style={
                ({isActive}) => (
                isActive 
                ? {
                    color: '#2052F1'
                  }
                :{}
                )
              }
            >Usuarios</NavLink>
          </li>
          <li>
            <Link to="/profile" className="user-link">
              <span className="material-icons-outlined">person</span>
              <p>{`${session?.username || ''}`}</p>
            </Link>
          </li>
          <li>
            <a
              href="/"
              className="btn btn--simple"
              onClick={(evt) => {
                evt.preventDefault();
                deleteSession();
                setTimeout(() => {
                  window.location.href = '/';
                }, 100);
              }}
            >
              Cerrar sesión
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderComponent;
