import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getGamePlayedById, updateGamePlayed } from '../../api/gamesApi';
import HeaderComponent from '../../components/Header/header.component';
import { IGamePlayed } from '../../types/GamesInterface';
import { IPlayer } from '../../types/PlayerInterface';
import { IUser } from '../../types/UserInterface';
import { getSession } from '../../utils/helpers';

const EditGameScreen = () => {
  const [currentSession, setSession] = useState<IUser | null>();
  const [game, setGame] = useState<Partial<IGamePlayed>>({});
  const params = useParams();

  useEffect(() => {
    const session = getSession();
    setSession(session);
    console.log({ params, session });
    if (params.id && session && session.token) {
      getGamePlayedById(params.id || '', session.token)
        .then((game) => {
          setGame(game);
        })
        .catch((error) => {
          Swal.fire('Oops', error, 'error');
        });
    }
  }, []);

  const currentGameScore = { ...game }.score;

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
          <section className="contain-top-2 col-12 col-reset">
            <div className="title__back">
              <Link to={`/game/${game._id}`} className="btn btn--simple-back">
                <span className="material-icons">arrow_back</span>{' '}
                <p>Regresar</p>
              </Link>
            </div>
            <div className="title-board pt-0">
              <h1>Editar partida</h1>
            </div>
            <div className="detail-info col-6 mx-auto">
              <div className="row mb16">
                <div className="col-6">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>Partida Id.</strong>
                    </p>
                    <p className="detail-data__id">
                      <span>
                        {game._id
                          ? '#' + `${game._id}`.substr(`${game._id}`.length - 5)
                          : ''}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="detail-data">
                    <p className="detail-data__name">
                      <strong>
                        {game.playerId ? (game.playerId as IPlayer).name : ''}
                      </strong>
                    </p>
                    <p className="detail-data__transaction">
                      <span>
                        #
                        {game && game.playerId
                          ? ((game.playerId as IPlayer)._id || '').substr(
                              `${(game.playerId as IPlayer)._id}`.length - 5
                            )
                          : ''}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <form>
                  <div className="game-form__row">
                    <label htmlFor="km" className="form-label">
                      Score
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="km"
                      placeholder={`${(currentGameScore || 0).toFixed(2) || 0}`}
                      inputMode="decimal"
                      onChangeCapture={(evt) => {
                        const value =
                          (evt.target as HTMLTextAreaElement).value || '';
                        setGame({
                          ...game,
                          score: Number(value),
                        });
                      }}
                    />
                  </div>
                  <div className="game-form__row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="winner"
                        checked={game.winner}
                        onChange={(evt) =>
                          setGame({
                            ...game,
                            winner: evt.target.checked,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="winner">
                        ¿Es ganador?
                      </label>
                    </div>
                  </div>
                  <div className="game-form__row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="payment"
                        checked={game.isCash || false}
                        onChange={(evt) =>
                          setGame({
                            ...game,
                            isCash: evt.target.checked,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="payment">
                        ¿Pago en efectivo u otro medio?
                      </label>
                    </div>
                  </div>
                  <div className="control-box control--100">
                    <div className="control-box__col col-12 col-reset">
                      <button
                        className="btn btn--type1"
                        onClick={async (e) => {
                          e.preventDefault();
                          return Swal.fire({
                            title: '¿Estás segur@?',
                            text: '¿Deseas guardar los cambios?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Si, guardar',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              return updateGamePlayed(
                                game._id || '',
                                {
                                  ...game,
                                  playerId: (game.playerId as IPlayer)._id,
                                },
                                currentSession?.token || ''
                              )
                                .then(() => {
                                  setTimeout(() => {
                                    window.location.replace(
                                      `/game/${game._id}`
                                    );
                                  }, 1000);
                                  return Swal.fire(
                                    'Finalizado',
                                    'El juego ha sido guardado. Gracias',
                                    'success'
                                  );
                                })
                                .catch((error) => {
                                  Swal.fire('Oops', error, 'error');
                                });
                            }
                          });
                        }}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default EditGameScreen;
